input[type='text'],
input[type='search'],
input[type='email'],
select {
  border-radius: 3px;
  border: 1px solid var(--grey-color);
  background: #fff;
  color: #000;
  font-family: inherit;
  outline: none;
  padding: 0 1em;
  transition: all 0.5s;
  height: 2.7em;
  line-height: 2.7em;
  width: 100%;
}

input:focus,
select:focus {
  border: 1px solid var(--primary-color);
}

input:disabled,
select:disabled {
  color: var(--grey-color);
}

input::placeholder {
  color: var(--grey-color-dark);
}