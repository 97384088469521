.invite-speaker .invited-by {
  font-weight: 600;
}

.invite-speaker .invited-by img {
  font-size: 1.5em;
}

.invite-speaker h2 {
  margin: 1.5em 0 2em 0;
}

.invite-join-btn {
  margin-top: 1em;
}
