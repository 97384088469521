.profile .profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  padding: 0;
}

.profile .profile-avatar {
  --cc-avatar-height: 100px;
  --cc-avatar-width: 100px;
  --cc-avatar-font-size: 70px;
  margin-right: 1.5em;
}

.profile .profile-avatar img {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.profile .profile-header h1 {
  margin-top: 0;
  color: var(--primary-color);
}

.profile .profile-form {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.profile .profile-form > * {
  margin-bottom: 0.7em;
}

.profile .profile-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .profile .profile-header {
    margin-bottom: 1em;
  }

  .profile .profile-header > img {
    margin-right: 1em;
  }

  .profile .profile-header h1 {
    font-size: 1.2em;
  }
}
