.dropdown {
  position: relative;
  outline: none;
  z-index: 1;
}

.dropdown .dropdown-menu {
  position: absolute;
  right: 0;
  background: var(--primary-color);
  border-radius: 3px;
  box-shadow: 0px 1px 5px 0px #111;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding: 5px 0;
}

.dropdown .dropdown-menu > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3em;
  padding: 0 1em;
}

.dropdown .dropdown-menu > div {
  font-size: 0.9em;
  font-weight: bold;
  color: var(--grey-color-dark);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.dropdown .dropdown-menu > a {
  text-decoration: none;
  color: var(--grey-color-light);
}

.dropdown .dropdown-menu > a:hover {
  color: var(--primary-color-font);
  background: var(--primary-color-light);
  text-decoration: none;
}

.dropdown .dropdown-menu > button {
  color: var(--grey-color-light);
  border: none;
  background: var(--primary-color);
}

.dropdown .dropdown-menu > button .icon-label {
  justify-content: flex-start;
}

.dropdown .dropdown-menu > button:hover {
  cursor: pointer;
  color: var(--primary-color-font);
  background: var(--primary-color-light);
}
