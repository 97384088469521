h1,
h2,
h3,
h4 {
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  color: var(--grey-color-dark);
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  letter-spacing: 1px;
  font-size: 1.1em;
}

h4 {
  letter-spacing: 0;
  text-transform: none;
  font-weight: 600;
  margin-bottom: 0;
}
