.thread {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.description {
  padding-top: 1em;
  padding-bottom: 2em;
  flex-grow: 1;
}

.messages {
  overflow: auto;
  padding: 1em 1em 1em 0;
}

.message {
  margin-top: 1.5em;
}

.input {
  flex-shrink: 0;
  display: flex;
}

.input button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input input[type=text] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}