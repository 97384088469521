.form {
  padding: 2em;
  display: flex;
  flex-direction: column;
}

.form > * {
  margin-bottom: 1em;
}

.form > *:last-child {
  margin-bottom: 0;
}

.label {
  display: flex;
  align-items: center;
}

.description {
  margin-left: 2em;
  font-size: 0.9em;
  color: var(--grey-color-dark);
}

@media only screen and (max-width: 480px) {
  .form {
    padding: 1em;
  }
}
