.confirmation-popin {
  min-width: 400px;
}

.confirmation-popin .confirmation-title {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
}

.confirmation-popin .confirmation-content {
  font-size: 1.2em;
  font-weight: 200;
  margin-bottom: 2em;
}

.confirmation-popin > .confirmation-actions {
  display: flex;
  justify-content: flex-end;
}

.confirmation-popin > .confirmation-actions > button:not(:first-child) {
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .confirmation-popin {
    display: flex;
    flex-direction: column;
  }

  .confirmation-popin .confirmation-text {
    flex-grow: 1;
  }

  .confirmation-popin > .confirmation-actions {
    flex-grow: 0;
  }

  .confirmation-popin > .confirmation-actions > button {
    width: 100%;
  }
}