.toggle {
  display: inline-block;
  --toggle-height: 24px;
  --toggle-width: 42px;
  height: var(--toggle-height);
  width: var(--toggle-width);
  position: relative;
  cursor: pointer;
}

.toggle input[type='checkbox'] {
  display: none;
}

.toggle-item {
  display: inline-block;
  height: var(--toggle-height);
  width: var(--toggle-width);
  border: 1px solid var(--grey-color);
  border-radius: calc(var(--toggle-height) / 2);
  transition: all 0.3s ease-in;
}

.toggle-item::before {
  content: ' ';
  height: calc(var(--toggle-height));
  width: calc(var(--toggle-height));
  border: 1px solid var(--grey-color);
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.3s ease-in;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 1px 1px rgba(0, 0, 0, 0.08);
}

.toggle-item:hover::before{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 1px 1px rgba(0, 0, 0, 0.08),
    0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 4px 9px 2px rgba(0, 0, 0, 0.08);
}

input[type='checkbox']:checked + .toggle-item  {
  border: 1px solid var(--secondary-color);
  background: var(--secondary-color);
}

input[type='checkbox']:checked + .toggle-item::before {
  border: 1px solid var(--secondary-color);
  left: calc(var(--toggle-width) - var(--toggle-height));
}
