a {
  color: var(--primary-color);
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  transition: all 0.3s;
}

a[role='button'] {
  cursor: pointer;
}

a:hover {
  color: var(--secondary-color);
}
