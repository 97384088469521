.cc-tooltip {
  font-size: .8em;
}

.cc-tooltip[data-placement^="top"] .cc-tooltip-arrow  {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: 0;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip[data-placement^="bottom"] .cc-tooltip-arrow  {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: 0;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip[data-placement^="left"] .cc-tooltip-arrow  {
  border-width: 5px 0 5px 5px;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip[data-placement^="right"] .cc-tooltip-arrow  {
  border-width: 5px 5px 5px 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip .cc-tooltip-arrow  {
  border-color: var(--primary-color);
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.cc-tooltip .cc-tooltip-inner {
  margin: 5px;
  padding: .8em;
  background-color: var(--primary-color);
  color: var(--primary-color-font);
  border-radius: .2em;
}