.event-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-actions > * {
  margin-right: 1em;
}

.event-actions > *:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1024px) {
  .event-actions {
    display: flex;
    flex-direction: column;
  }

  .event-actions > * {
    margin-right: 0;
    margin-bottom: 1em;
    width: 100%;
  }

  .event-actions > *:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

}