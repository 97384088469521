.invite-organizer .invited-by {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.invite-organizer .invited-by img {
  font-size: 1.5em;
}

.invite-organizer h2 {
  margin: 1.5em 0 2em 0;
}

.invite-join-btn {
  margin-top: 1em;
}
