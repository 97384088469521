.proposal {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'proposal-actions proposal-actions' 'proposal-ratings proposal-ratings' 'proposal-talk proposal-speakers' 'proposal-likers proposal-likers';
}

.proposal .card {
  padding: 1.5em;
}

.proposal-ratings {
  grid-area: proposal-ratings;
  align-self: start;
}

.proposal-actions {
  grid-area: proposal-actions;
}

.proposal-actions:empty {
  margin-bottom: 0;
  padding: 0;
}

.proposal-talk {
  grid-area: proposal-talk;
}

.proposal-speakers {
  grid-area: proposal-speakers;
}

.proposal-speakers > div {
  border-bottom: 1px solid var(--grey-color-light);
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.proposal-speakers > div:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.proposal-likers {
  grid-area: proposal-likers;
}

@media only screen and (max-width: 480px) {
  .proposal {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'proposal-ratings'
      'proposal-talk'
      'proposal-speakers'
      'proposal-likers';
  }

  .proposal-actions {
    display: none;
  }

  .proposal .card {
    padding: 1em;
  }
}
