.proposal-ratings-layout {
  display: grid;
  grid-template-columns: auto 2fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'btn-previous btn-ratings btn-next';
  grid-gap: 1em;
  align-items: center;
}

.btn-previous {
  grid-area: btn-previous;
  justify-self: flex-start;
}

.btn-ratings {
  grid-area: btn-ratings;
  justify-self: center;
}

.btn-next {
  grid-area: btn-next;
  justify-self: flex-end;
}

@media only screen and (max-width: 1080px) {
  .proposal-ratings-layout {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'btn-previous btn-next' 'btn-ratings btn-ratings';
  }
}

