.talk-submission {
  width: 100%;
}

.submit-talk-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.submit-talk-form > * {
  margin-bottom: 0.7em;
}
