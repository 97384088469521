.default-theme {
  --font-size: 14px;
  --background-color: #edf2f5;

  --primary-color: #2a3244;
  --primary-color-light: #373f51;
  --primary-color-dark: #1f2022;
  --primary-color-font: #fff;

  --secondary-color: #ffab00;
  --secondary-color-light: #ffdd4b;
  --secondary-color-dark: #c67c00;
  --secondary-color-font: #fff;

  --grey-color: #c4c9d6;
  --grey-color-dark: #95949b;
  --grey-color-light: #eee;

  --layout-navbar-height: 60px;
  --layout-sidebar-width: 240px;

  --info-color: #fff;
  --success-color: #7fba01;
  --question-color: #2170bf;
  --error-color: #c73228;
  --warning-color: #ff9900;
}
