.category-form > h2 {
  margin: 0;
  padding-bottom: 2em;
}

.category-form .category-form-content {
  display: flex;
  flex-direction: column;
}

.category-form .category-form-content > div {
  margin-bottom: 0.7em;
}

.category-form .category-form-content > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .category-form .category-form-content {
    padding: 1em;
  }
}
