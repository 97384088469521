.beta-access {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background: var(--primary-color);
  color: var(--grey-color);
}

.beta-access h1.beta-access-title {
  color: var(--primary-color-font);
  text-transform: uppercase;
  margin-bottom: 1em;
}

.beta-access .beta-access-form {
  width: 50%;
}

.beta-access .beta-access-error {
  margin-top: 1em;
  color: var(--error-color);
}

@media only screen and (max-width: 1024px) {
  .beta-access .beta-access-form {
    width: 70%;
  }
}

@media only screen and (max-width: 480px) {
  .beta-access .beta-access-form {
    width: 95%;
  }
}
