.brand {
  background: var(--secondary-color);
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--secondary-color-font);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s;
  padding: 0 1em;
}

.brand > a {
  text-decoration: none;
  color: var(--secondary-color-font);
}

.brand > a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.brand > .burgerLink {
  width: 40px;
}

.brand > .burgerLink:hover {
  color: var(--secondary-color-font);
}

.mobileSidebarPortal {
  top: var(--layout-navbar-height);
  z-index: 50;
}

.mobileSidebar {
  height: calc(100% - var(--layout-navbar-height));
}
