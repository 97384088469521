.icon-label {
  display: flex;
  align-items: center;
}

button .icon-label {
  justify-content: center;
}

.icon-label .fa {
  width: 1em;
  text-align: center;
  margin-right: 0.6em;
}

.icon-label-right .fa {
  margin-left: 0.6em;
  margin-right: 0;
}

.icon-label-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


@media only screen and (max-width: 480px) {
  .icon-label-overflow {
    white-space: normal;
  }
}