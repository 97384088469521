@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.app {
  background: var(--background-color);
  color: var(--primary-color);
  font-size: var(--font-size);
  height: 100%;
  overflow: hidden;
}

@media print{
  @page {
    size: A4;
    margin: 0;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }

  .app {
    height: auto;
    overflow: visible;
    background: #fff;
  }

  .no-print {
    display: none;
  }
}