.navbar {
  background: var(--primary-color);
  color: var(--primary-color-font);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.5em;
}

.navbar-left {
  font-size: 1.2em;
  margin-left: 1.5em;
  letter-spacing: 1px;
}

.navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-right > * {
  margin-left: 1.5em;
}

.navbar-link {
  text-decoration: none;
  color: var(--grey-color);
}
