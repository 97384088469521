.contributors-list {
  margin: 3em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contributors-list > * {
  margin: 1em;
}
