.cc-badge {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  border-radius: .25rem;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
}

.cc-badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.cc-badge-outline {
  font-weight: 400;
}

.cc-badge-primary {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--primary-color-font);
}

.cc-badge-primary.cc-badge-outline {
  border: 1px solid var(--primary-color);
  background-color: #FFF;
  color: var(--primary-color);
}

.cc-badge-secondary {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--secondary-color-font);
}

.cc-badge-secondary.cc-badge-outline {
  border: 1px solid var(--secondary-color);
  background-color: #FFF;
  color: var(--secondary-color);
}

.cc-badge-success {
  border: 1px solid var(--success-color);
  background-color: var(--success-color);
  color: #FFF;
}

.cc-badge-success.cc-badge-outline {
  border: 1px solid var(--success-color);
  background-color: #FFF;
  color: var(--success-color);
}

.cc-badge-warning {
  border: 1px solid var(--warning-color);
  background-color: var(--warning-color);
  color: #FFF;
}

.cc-badge-warning.cc-badge-outline {
  border: 1px solid var(--warning-color);
  background-color: #FFF;
  color: var(--warning-color);
}

.cc-badge-error {
  border: 1px solid var(--error-color);
  background-color: var(--error-color);
  color: #FFF;
}

.cc-badge-error.cc-badge-outline {
  border: 1px solid var(--error-color);
  background-color: #FFF;
  color: var(--error-color);
}

.cc-badge-info {
  border: 1px solid var(--question-color);
  background-color: var(--question-color);
  color: #FFF;
}

.cc-badge-info.cc-badge-outline {
  border: 1px solid var(--question-color);
  background-color: #FFF;
  color: var(--question-color);
}

.cc-badge-dark {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}

.cc-badge-dark.cc-badge-outline {
  border: 1px solid #000;
  background-color: #FFF;
  color: #000;
}

.cc-badge-light {
  border: 1px solid var(--grey-color-light);
  background-color: var(--grey-color-light);
  color: #000;
}

.cc-badge-light.cc-badge-outline {
  border: 1px solid var(--grey-color-dark);
  background-color: #FFF;
  color: var(--grey-color-dark);
}