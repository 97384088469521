.sidebar {
  background-color: var(--primary-color);
  height: 100%;
}

.sidebar .sidebar-panel {
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-header {
  color: var(--grey-color-dark);
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 1px;
  overflow: hidden;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-top: 1.5em;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.sidebar a.sidebar-link {
  color: var(--grey-color-light);
  text-decoration: none;
  padding: 0.7em 1em;
}

.sidebar a.sidebar-link-active {
  color: var(--secondary-color);
  background: var(--primary-color-light);
}

.sidebar a.sidebar-link:hover {
  color: var(--primary-color-font);
  background: var(--primary-color-light);
  text-decoration: none;
}

.sidebar a.sidebar-link-active:hover {
  color: var(--secondary-color);
}
