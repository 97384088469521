.items-form {
  display: flex;
  flex-wrap: wrap;
}

.items-form > button {
  min-width: initial;
  margin: 0 0.5em 0.5em 0;
}

.items-form > div {
  height: 100%;
  margin: 0 0.5em 0.5em 0;
}
