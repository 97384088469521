.red-theme {
  --background-color: #edf2f5;

  --primary-color: #2a3244;
  --primary-color-light: #373f51;
  --primary-color-dark: #1f2022;
  --primary-color-font: #fff;

  --secondary-color: #fc1047;
  --secondary-color-light: #fd7fa0;
  --secondary-color-dark: #db0c3e;
  --secondary-color-font: #fff;
}
