a.add-speaker-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--grey-color-dark);
}

.add-speaker-button-icon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: var(--grey-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-speaker-button-label {
  margin-left: .8em;
  text-decoration: underline;
}