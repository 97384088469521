.icon-submitted {
  text-align: center;
  color: var(--grey-color-dark);
}

.icon-submitted > i {
  font-size: 8em;
  margin: 0.3em;
}

.icon-submitted > h2 {
  font-weight: 600;
  color: var(--success-color);
}
