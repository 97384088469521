.proposal-talk-badges {
  margin-bottom: 1em;
}

.proposal-talk-badges > * {
  margin-right: 1em;
}

.proposal-talk-info > h3 {
  margin-top: 2em;
}
