.drawer-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size);
  min-width: 33vw;
  max-width: 66vw;
  padding: 2em;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) 0 10px 10px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  z-index: 120;
}

.drawer-header:before,
.drawer-actions:before {
  content: '';
  position: absolute;
  height: 0.5em;
  width: 100%;
  pointer-events: none;
}

.drawer-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 0.5em;
}

.drawer-header:before {
  top: 100%;
  background: linear-gradient(#fff, #fff, 0);
}

.drawer-titles {
  flex-grow: 1;
  font-weight: 400;
}

.drawer-title {
  font-size: 1.5em;
  font-weight: 700;
}

.drawer-subtitle {
  margin-top: 0.5em;
  font-size: 0.8em;
  font-weight: 200;
}

.drawer-content {
  overflow: auto;
  height: 100%;
  padding: 1em 0;
}

.drawer-actions {
  position: relative;
  padding-top: 0.5em;
}

.drawer-actions:before {
  bottom: 100%;
  background: linear-gradient(transparent, #fff);
}

@media only screen and (max-width: 480px) {
  .drawer-sidebar {
    left: 0;
    max-width: 100%;
    width: 100%;
    padding: 1em;
  }

  .drawer-actions {
    text-align: center;
  }

  .drawer-actions > * {
    width: 100%;
  }
}
