.submission-status {
  display: flex;
  align-items: center;
  justify-self: flex-start;
}

.submission-status > * {
  margin-left: 1em;
}

.submission-status > *:first-child {
  margin-left: 0;
}

.submission-status .status-warning {
  background: var(--warning-color);
}

.submission-status .status-warning > a {
  color: #fff;
  text-decoration: none;
}

.submission-status .status-closed {
  background: var(--error-color);
}
