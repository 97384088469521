@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--primary-color);
  color: var(--primary-color-font);
}

.page-not-found > h1 {
  font-size: 3em;
  margin: 0 0 1em 0;
  text-align: center;
}

.page-not-found > a {
  color: var(--secondary-color);
  text-decoration: underline;
}

.home {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  background: var(--primary-color);
}

.home .home-link {
  align-items: center;
  background: var(--secondary-color);
  border-radius: 0.5em;
  color: var(--primary-color-font);
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 1px;
  margin: 2.5em;
  min-height: 12em;
  min-width: 12em;
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.home .home-link > i {
  font-size: 5em;
}

.home .home-link > span {
  margin-top: 1.5em;
  white-space: nowrap;
}

.home .home-link:hover {
  background: var(--primary-color-font);
  color: var(--secondary-color);
  text-decoration: none;
}

@media only screen and (max-width: 640px) {
  .home {
    flex-direction: column;
  }

  .home .home-link {
    font-size: 0.9em;
  }
}

/**
 * Spinner by @tobiasahlin
 * http://tobiasahlin.com/spinkit/
 */
 div.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--secondary-color);
  margin: 10px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    transform: scale(1);
  }
}

.icon-label {
  display: flex;
  align-items: center;
}

button .icon-label {
  justify-content: center;
}

.icon-label .fa {
  width: 1em;
  text-align: center;
  margin-right: 0.6em;
}

.icon-label-right .fa {
  margin-left: 0.6em;
  margin-right: 0;
}

.icon-label-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


@media only screen and (max-width: 480px) {
  .icon-label-overflow {
    white-space: normal;
  }
}
.cc-button {
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  padding: 10px 16px;
  position: relative;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1em;
}


/* Button sizes */
.cc-button-small {
  padding: 8px 12px;
  font-size: 0.7em;
}

.cc-button-large {
  padding: 12px 18px;
  font-size: 1.3em;
}


/* Button hierarchy */
.cc-button-primary {
  background: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color-font);
  fill: var(--primary-color-font);
}

.cc-button-primary:hover {
  background: var(--primary-color-dark);
  border: 1px solid var(--primary-color-dark);
  color: var(--primary-color-font);
  fill: var(--primary-color-font);
}

.cc-button-secondary {
  background: #fff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  fill: var(--primary-color);
}

.cc-button-secondary:hover {
  background: #fff;
  border: 1px solid var(--primary-color-dark);
  color: var(--primary-color-dark);
  fill: var(--primary-color-dark);
}

.cc-button-tertiary {
  background: transparent;
  border: 1px solid transparent;
  color: var(--primary-color);
  fill: var(--primary-color);
}

.cc-button-tertiary:hover {
  color: var(--primary-color-dark);
  fill: var(--primary-color-dark);
}


/* Accent button by hierarchy */
.cc-button-primary.cc-button-accent {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color-font);
  fill: var(--secondary-color-font);
}

.cc-button-primary.cc-button-accent:hover {
  background: var(--secondary-color-dark);
  border: 1px solid var(--secondary-color-dark);
  color: var(--secondary-color-font);
  fill: var(--secondary-color-font);
}

.cc-button-secondary.cc-button-accent {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  fill: var(--secondary-color);
}

.cc-button-secondary.cc-button-accent:hover {
  border: 1px solid var(--secondary-color-dark);
  color: var(--secondary-color-dark);
  fill: var(--secondary-color-dark);
}

.cc-button-tertiary.cc-button-accent {
  background: transparent;
  border: 1px solid transparent;
  color: var(--secondary-color);
  fill: var(--secondary-color);
}

.cc-button-tertiary.cc-button-accent:hover {
  color: var(--secondary-color-dark);
  fill: var(--secondary-color-dark);
}


/* Error button by hierarchy */
.cc-button-primary.cc-button-error {
  background: var(--error-color);
  border: 1px solid var(--error-color);
  color: #fff;
  fill: #fff;
}

.cc-button-primary.cc-button-error:hover {
  background: var(--error-color);
  border: 1px solid var(--error-color);
  color: #fff;
  fill: #fff;
}

.cc-button-secondary.cc-button-error {
  border: 1px solid var(--error-color);
  color: var(--error-color);
  fill: var(--error-color);
}

.cc-button-secondary.cc-button-error:hover {
  border: 1px solid var(--error-color);
  color: var(--error-color);
  fill: var(--error-color);
}

.cc-button-tertiary.cc-button-error {
  background: transparent;
  border: 1px solid transparent;
  color: var(--error-color);
  fill: var(--error-color);
}

.cc-button-tertiary.cc-button-error:hover {
  color: var(--error-color);
  fill: var(--error-color);
}


/* button disabled */
.cc-button:disabled {
  background: var(--grey-color-light);
  border: 1px solid var(--grey-color-light);
  color: var(--grey-color-dark);
  fill: var(--grey-color-dark);
  cursor: not-allowed;
}

.cc-button:disabled:hover {
  background: var(--grey-color-light);
  border: 1px solid var(--grey-color-light);
  color: var(--grey-color-dark);
  fill: var(--grey-color-dark);
}

.cc-button-secondary.cc-button:disabled {
  border: 1px solid var(--grey-color-dark);
  background: none;
}

.cc-button-secondary.cc-button:disabled:hover {
  border: 1px solid var(--grey-color-dark);
  background: none;
}

.cc-button-tertiary.cc-button:disabled {
  background: none;
  border: none;
}

.cc-button-tertiary.cc-button:disabled:hover {
  background: none;
  border: none;
}


/** block button */
.cc-button-block {
  display: block;
  width: 100%;
}


/** simple button */
.cc-button-simple {
  padding: 0;
  border: none;
  background: none;
  color: var(--primary-color);
  fill: var(--primary-color);
}

.cc-button-simple:hover {
  border: none;
  background: none;
  color: var(--primary-color);
  fill: var(--primary-color);
  opacity: 0.7;
}
.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--primary-color);
}

.login .login-title {
  text-transform: uppercase;
  margin-bottom: 1em;
  color: var(--primary-color-font);
}

.login .login-back {
  margin-top: 1em;
  color: var(--secondary-color);
  text-decoration: underline;
}

.login .login-error {
  background: var(--background-color);
  color: var(--error-color);
  padding: 1em;
  max-width: 20em;
  border-radius: 3px;
}

.login button {
  margin: 1em 0;
  min-width: 20em;
}

.login button.btn-google {
  background: #ea2533;
  color: #fff;
}

.login button.btn-google:hover {
  background: #fff;
  color: #ea2533;
  opacity: 1;
}

.login button.btn-twitter {
  background: #34ccfe;
  color: #fff;
}

.login button.btn-twitter:hover {
  background: #fff;
  color: #34ccfe;
  opacity: 1;
}

.login button.btn-github {
  background: #000;
  color: #fff;
}

.login button.btn-github:hover {
  background: #fff;
  color: #000;
  opacity: 1;
}

.login button.btn-facebook {
  background: #3b5998;
  color: #fff;
}

.login button.btn-facebook:hover {
  background: #fff;
  color: #3b5998;
  opacity: 1;
}

.portal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.cc-avatar-wrapper {
  --cc-avatar-height: 40px;
  --cc-avatar-width: 40px;
  --cc-avatar-font-size: 1.3rem;
  align-items: center;
  display: inline-flex;
}

.cc-avatar {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 50%;
}

.cc-avatar-small {
  --cc-avatar-height: 20px;
  --cc-avatar-width: 20px;
  --cc-avatar-font-size: 0.8rem;
}

.cc-avatar-large {
  --cc-avatar-height: 60px;
  --cc-avatar-width: 60px;
  --cc-avatar-font-size: 2rem;
}

.cc-avatar-square {
  border-radius: 0;
}

.cc-avatar-square.cc-avatar-image > img {
  border-radius: 0;
}

.cc-avatar-image > img {
  border-radius: 50%;
  height: var(--cc-avatar-height);
  width: var(--cc-avatar-width);
}

.cc-avatar-initials {
  background: var(--secondary-color);
  color: var(--secondary-color-font);
  height: var(--cc-avatar-height);
  width: var(--cc-avatar-width);
}

.cc-avatar-initials > * {
  font-weight: 300;
  font-size: var(--cc-avatar-font-size);
}

.cc-avatar-label {
  margin-left: 0.5em;
}

.dropdown {
  position: relative;
  outline: none;
  z-index: 1;
}

.dropdown .dropdown-menu {
  position: absolute;
  right: 0;
  background: var(--primary-color);
  border-radius: 3px;
  box-shadow: 0px 1px 5px 0px #111;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  padding: 5px 0;
}

.dropdown .dropdown-menu > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3em;
  padding: 0 1em;
}

.dropdown .dropdown-menu > div {
  font-size: 0.9em;
  font-weight: bold;
  color: var(--grey-color-dark);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.dropdown .dropdown-menu > a {
  text-decoration: none;
  color: var(--grey-color-light);
}

.dropdown .dropdown-menu > a:hover {
  color: var(--primary-color-font);
  background: var(--primary-color-light);
  text-decoration: none;
}

.dropdown .dropdown-menu > button {
  color: var(--grey-color-light);
  border: none;
  background: var(--primary-color);
}

.dropdown .dropdown-menu > button .icon-label {
  justify-content: flex-start;
}

.dropdown .dropdown-menu > button:hover {
  cursor: pointer;
  color: var(--primary-color-font);
  background: var(--primary-color-light);
}

.avatar-dropdown-button .cc-avatar-image > img {
  border: 2px solid var(--primary-color-font);
  font-size: 1.5em;
}

.avatar-dropdown-button .cc-avatar-image > img:hover {
  border: 2px solid var(--secondary-color);
  cursor: pointer;
}

.avatar-dropdown-button .cc-avatar-initials {
  border: 2px solid var(--secondary-color);
}

.avatar-dropdown-button .cc-avatar-initials:hover {
  border: 2px solid var(--secondary-color-font);
  cursor: pointer;
}

.avatar-dropdown {
  z-index: 100;
}

.brand_brand__1nWHM {
  background: var(--secondary-color);
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--secondary-color-font);
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding: 0 1em;
}

.brand_brand__1nWHM > a {
  text-decoration: none;
  color: var(--secondary-color-font);
}

.brand_brand__1nWHM > a:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.brand_brand__1nWHM > .brand_burgerLink__W6nuY {
  width: 40px;
}

.brand_brand__1nWHM > .brand_burgerLink__W6nuY:hover {
  color: var(--secondary-color-font);
}

.brand_mobileSidebarPortal__rZk5t {
  top: var(--layout-navbar-height);
  z-index: 50;
}

.brand_mobileSidebar__1c2V0 {
  height: calc(100% - var(--layout-navbar-height));
}

.icon-link {
  display: block;
}

.navbar {
  background: var(--primary-color);
  color: var(--primary-color-font);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.5em;
}

.navbar-left {
  font-size: 1.2em;
  margin-left: 1.5em;
  letter-spacing: 1px;
}

.navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-right > * {
  margin-left: 1.5em;
}

.navbar-link {
  text-decoration: none;
  color: var(--grey-color);
}

.layout-navbar {
  grid-area: navbar;
}

.layout-sidebar {
  grid-area: sidebar;
  overflow: auto;
}

.layout-main {
  grid-area: main;
  overflow: auto;
}

.layout-main > * {
  margin: 3em;
}

.layout-main.layout-main-full-width {
  padding: 0 3em;
}

.layout-main.layout-main-full-width > * {
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
}

.layout-brand {
  grid-area: brand;
}

.layout-screen {
  display: grid;
  grid-template-columns: var(--layout-sidebar-width) auto;
  grid-template-rows: var(--layout-navbar-height) auto;
  grid-template-areas: 'brand navbar' 'sidebar main';
  height: 100%;
}

.layout-screen.layout-screen-full-width {
  grid-template-areas: 'brand navbar' 'main main';
}

@media only screen and (max-width: 1023px) {
  .layout-screen {
    grid-template-columns: auto;
    grid-template-areas: 'brand' 'main';
  }
  .layout-screen.layout-screen-full-width {
    grid-template-areas: 'brand' 'main';
  }
  .layout-main.layout-main-full-width {
    padding: 0;
  }
  .layout-navbar {
    display: none;
  }
  .layout-sidebar {
    display: none;
  }
  .layout-main > * {
    margin: 0;
    padding: 1em;
  }
}

@media print{
  .layout-screen {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'main';
    height: 100%;
  }
  .layout-navbar {
    display: none;
  }
  .layout-sidebar {
    display: none;
  }
  .layout-brand {
    display: none;
  }
  .layout-main > * {
    margin: 0em;
  }
}
.contributor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contributor-avatar {
  margin-bottom: 1em;
}

.contributor-name {
  font-weight: 600;
}

.contributors-list {
  margin: 3em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contributors-list > * {
  margin: 1em;
}

.maps {
  height: 100%;
  width: 100%;
  border: 0;
}

.markdown {
  line-height: 1.5em;
}

.markdown code {
  white-space: pre-line;
}
.cc-badge {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  border-radius: .25rem;
  vertical-align: baseline;
  text-align: center;
  white-space: nowrap;
}

.cc-badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem;
}

.cc-badge-outline {
  font-weight: 400;
}

.cc-badge-primary {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--primary-color-font);
}

.cc-badge-primary.cc-badge-outline {
  border: 1px solid var(--primary-color);
  background-color: #FFF;
  color: var(--primary-color);
}

.cc-badge-secondary {
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: var(--secondary-color-font);
}

.cc-badge-secondary.cc-badge-outline {
  border: 1px solid var(--secondary-color);
  background-color: #FFF;
  color: var(--secondary-color);
}

.cc-badge-success {
  border: 1px solid var(--success-color);
  background-color: var(--success-color);
  color: #FFF;
}

.cc-badge-success.cc-badge-outline {
  border: 1px solid var(--success-color);
  background-color: #FFF;
  color: var(--success-color);
}

.cc-badge-warning {
  border: 1px solid var(--warning-color);
  background-color: var(--warning-color);
  color: #FFF;
}

.cc-badge-warning.cc-badge-outline {
  border: 1px solid var(--warning-color);
  background-color: #FFF;
  color: var(--warning-color);
}

.cc-badge-error {
  border: 1px solid var(--error-color);
  background-color: var(--error-color);
  color: #FFF;
}

.cc-badge-error.cc-badge-outline {
  border: 1px solid var(--error-color);
  background-color: #FFF;
  color: var(--error-color);
}

.cc-badge-info {
  border: 1px solid var(--question-color);
  background-color: var(--question-color);
  color: #FFF;
}

.cc-badge-info.cc-badge-outline {
  border: 1px solid var(--question-color);
  background-color: #FFF;
  color: var(--question-color);
}

.cc-badge-dark {
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
}

.cc-badge-dark.cc-badge-outline {
  border: 1px solid #000;
  background-color: #FFF;
  color: #000;
}

.cc-badge-light {
  border: 1px solid var(--grey-color-light);
  background-color: var(--grey-color-light);
  color: #000;
}

.cc-badge-light.cc-badge-outline {
  border: 1px solid var(--grey-color-dark);
  background-color: #FFF;
  color: var(--grey-color-dark);
}
.eventTitle_title__100yX {
  width: 100%;
  text-align: center;
}

.eventTitle_name__2Xw_f {
  color: var(--primary-color);
  font-weight: 600;
}

.eventTitle_type__1L5pQ {
  margin-top: 1em;
  font-size: 0.5em;
}

.copy-input {
  display: flex;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  align-items: center;
}

.copy-input .copy-title {
  white-space: nowrap;
  color: var(--grey-color-dark);
  padding: 0 1em;
}

.copy-input > input[type='text'] {
  border: none;
  border-left: 1px solid var(--grey-color);
  border-radius: 0;
}

.copy-input > input[type='text']:focus {
  border: none;
  border-left: 1px solid var(--grey-color);
}

.copy-input > button {
  border: none;
  border-left: 1px solid var(--grey-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.copy-input > button:hover {
  border: none;
  border-left: 1px solid var(--grey-color);
}

.event-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-actions > * {
  margin-right: 1em;
}

.event-actions > *:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1024px) {
  .event-actions {
    display: flex;
    flex-direction: column;
  }

  .event-actions > * {
    margin-right: 0;
    margin-bottom: 1em;
    width: 100%;
  }

  .event-actions > *:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

}
.list-block > ul {
  list-style-type: none;
  margin: 0;
  padding-left: 1em;
  border-left: 2px solid var(--grey-color);
}

.list-block > ul > li {
  padding: 0.5em 0;
}

.list-block .list-item-name {
  font-weight: 600;
}

.list-block .list-item-description {
  padding-top: 0.5em;
  color: var(--grey-color-dark);
}

.cfp-block {
  margin: 1em 0;
}

.cfp-block .cfp-block-subtitle {
  font-size: 0.6em;
  margin-top: 1em;
}

.cfp-block.cfp-block-not-started * {
  color: var(--grey-color-dark);
}

.cfp-block.cfp-block-opened * {
  color: var(--success-color);
}

.cfp-block.cfp-block-closed * {
  color: var(--error-color);
}

.dates-block-range > i {
  margin: 0 1em;
}

.event-page {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'event-page-header event-page-header'
    'event-page-cfp event-page-cfp'
    'event-page-actions event-page-actions'
    'event-page-content event-page-info';
  grid-gap: 1em;
}

.event-page-header {
  grid-area: event-page-header;
}

.event-page-cfp {
  grid-area: event-page-cfp;
  text-align: center;
}

.event-page-actions {
  grid-area: event-page-actions;
}

.event-page-content {
  grid-area: event-page-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'event-content event-content'
    'event-categories event-formats';
  grid-gap: 2em;
}

.event-page-info {
  grid-area: event-page-info;
  align-self: start;
}

.event-page-info.card {
  padding: 0;
}

.event-page-info > iframe {
  width: 100%;
  min-height: 300px;
  background: var(--background-color);
}

.event-page-info-detail {
  margin: 2em;
}

.event-page-info-detail > * {
  margin-bottom: 2em;
}

.event-page-info-detail > *:last-child {
  margin-bottom: 0;
}

.event-page-info-detail a {
  text-decoration: underline;
}

.event-content {
  grid-area: event-content;
}

.event-content .event-description > h3 {
  margin-top: 2em;
}

.event-categories {
  grid-area: event-categories;
  align-self: flex-start;
}

.event-formats {
  grid-area: event-formats;
  align-self: flex-start;
}

@media only screen and (max-width: 1024px) {
  .event-page {
    grid-template-columns: 100%;
    grid-template-areas:
      'event-page-header'
      'event-page-cfp'
      'event-page-actions'
      'event-page-content'
      'event-page-info';
    grid-gap: 1em;
  }

  .event-page-content {
    grid-template-columns: auto;
    grid-template-areas:
      'event-content'
      'event-categories'
      'event-formats';
    grid-gap: 1em;
  }
}

.input-button {
  display: flex;
}

.input-button > input[type='search'],
.input-button > input[type='text'] {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: transparent !important;
}

.input-button > input[type='search']:focus,
.input-button > input[type='text']:focus {
  border-color: var(--grey-color);
}

.input-button > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-button > button:disabled {
  border-color: var(--grey-color);
}

.input-button > button:disabled:hover {
  border-color: var(--grey-color);
}

.beta-access {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background: var(--primary-color);
  color: var(--grey-color);
}

.beta-access h1.beta-access-title {
  color: var(--primary-color-font);
  text-transform: uppercase;
  margin-bottom: 1em;
}

.beta-access .beta-access-form {
  width: 50%;
}

.beta-access .beta-access-error {
  margin-top: 1em;
  color: var(--error-color);
}

@media only screen and (max-width: 1024px) {
  .beta-access .beta-access-form {
    width: 70%;
  }
}

@media only screen and (max-width: 480px) {
  .beta-access .beta-access-form {
    width: 95%;
  }
}

.toggle {
  display: inline-block;
  --toggle-height: 24px;
  --toggle-width: 42px;
  height: var(--toggle-height);
  width: var(--toggle-width);
  position: relative;
  cursor: pointer;
}

.toggle input[type='checkbox'] {
  display: none;
}

.toggle-item {
  display: inline-block;
  height: var(--toggle-height);
  width: var(--toggle-width);
  border: 1px solid var(--grey-color);
  border-radius: calc(var(--toggle-height) / 2);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.toggle-item::before {
  content: ' ';
  height: calc(var(--toggle-height));
  width: calc(var(--toggle-height));
  border: 1px solid var(--grey-color);
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 1px 1px rgba(0, 0, 0, 0.08);
}

.toggle-item:hover::before{
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 1px 1px rgba(0, 0, 0, 0.08),
    0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 4px 9px 2px rgba(0, 0, 0, 0.08);
}

input[type='checkbox']:checked + .toggle-item  {
  border: 1px solid var(--secondary-color);
  background: var(--secondary-color);
}

input[type='checkbox']:checked + .toggle-item::before {
  border: 1px solid var(--secondary-color);
  left: calc(var(--toggle-width) - var(--toggle-height));
}

.cc-address-input-wrapper {
  position: relative;
}

.cc-address-input-suggestions {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  z-index: 1;
}

.cc-address-input-suggestions:empty {
  border: none;
}

.cc-address-input-suggestion-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #fff;
  height: 2.7em;
  line-height: 2.7em;
  padding: 0 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cc-address-input-suggestion-item-active {
  background: var(--grey-color-light);
}
.preview-markdown {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border: 1px solid var(--grey-color);
  border-top: none;
  background: #fff;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.preview-markdown-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0.7em;
  border-top: 1px dotted var(--grey-color);
}

.preview-markdown-bar > a {
  text-decoration: none;
}

.preview-markdown-icon {
  display: flex;
  align-items: center;
  color: var(--grey-color);
  font-size: 0.9em;
}

.preview-markdown-icon > svg {
  width: 2em;
}

.preview-markdown-icon rect {
  fill: var(--grey-color);
}

.preview-markdown-icon path {
  fill: #fff;
}

.preview-markdown-display {
  height: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.preview-markdown-display > .preview-markdown-content {
  height: calc(100% - 31px);
  padding: 0.7em;
  overflow: auto;
  border-top: 1px solid var(--grey-color);
  border-radius: 3px;
}

.markdown-input {
  --md-header-size: 29px;
  position: relative;
  padding-bottom: var(--md-header-size);
}

.markdown-input > textarea {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.markdown-input > textarea:focus {
  border-bottom: none;
}

.markdown-input .markdown-input-focus {
  border-color: var(--primary-color);
  border-top-color: var(--grey-color);
}

.day-picker-custom {
  border-radius: 3px;
  background: #fff;
  border: 1px solid var(--grey-color);
}

.day-picker-custom .react-datepicker__input-container {
  cursor: pointer;
}

.day-picker-custom  .react-datepicker__triangle::before {
  border-bottom-color: var(--grey-color) !important;
}

.day-picker-custom .react-datepicker__triangle {
  border-bottom-color: #fff !important;
}

.day-picker-custom .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding-top: 1rem;
}

.day-picker-custom .react-datepicker__navigation {
  top: 1rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.day-picker-custom .react-datepicker__day-names {
  margin: 0.5rem 1rem 0 1rem;
}

.day-picker-custom .react-datepicker__day-name {
  color: var(--grey-color-dark);
}

.day-picker-custom .react-datepicker__day--outside-month {
  color: var(--grey-color);
}

.day-picker-custom .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  margin: 0;
}

.day-picker-custom .react-datepicker__month {
  margin: 0 1rem 1rem 1rem;
}

.day-picker-custom .react-datepicker__day:hover {
  border-radius: 0;
}

.day-picker-custom .react-datepicker__day--selected {
  border-radius: 0;
}

.day-picker-custom .react-datepicker__day--in-range {
  border-radius: 0;
  opacity: 0.8;
}

.day-picker-custom .react-datepicker__day--in-selecting-range {
  border-radius: 0;
}

@media (max-width: 400px), (max-height: 550px) {
  .day-picker-custom .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2.5rem;
    line-height: 2.5rem;
  }
}

.dayRangePicker_dayRangePicker__29RLs {
  display: flex;
  align-items: center;
}

.dayRangePicker_arrow__3VxL_ {
  margin: 0 1em;
}

.form-label {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-label > label {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 20%;
}

.form-label > div {
  width: 100%;
}

.form-has-error > label {
  color: var(--error-color);
}

.form-label .form-error {
  height: 1em;
  font-size: 0.8em;
  color: var(--error-color);
  margin-top: 0.3em;
}

.form-label .input-autocomplete {
  z-index: 1;
}

@media screen and (max-width: 640px) {
  .form-label {
    display: block;
  }
  .form-label > label {
    font-size: 0.9em;
    display: block;
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 0.3em;
  }
}

.form-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-checkbox > input {
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  line-height: normal;
  line-height: initial;
  margin-right: 1em;
  margin-top: 2px;
}

.form-radio input[type='radio'] {
  display: none;
}

.form-radio input[type='radio'] ~ label {
  border-radius: 3px;
  border: 1px solid var(--grey-color);
  cursor: pointer;
  display: block;
  padding: 10px;
  text-align: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.form-radio input[type='radio']:checked ~ label {
  background: var(--primary-color);
  color: #ffffff;
  border: 1px solid var(--primary-color);
}

.form-radio-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.form-radio-group-col {
  flex-direction: column;
}

.form-radio-group-col > * {
  margin-bottom: 15px;
}

.form-radio-group-col > *:last-child {
  margin-bottom: 0;
}

.form-radio-group-inline {
  flex-direction: row;
}

.form-radio-group-inline > * {
  margin-right: 15px;
  margin-bottom: 5px;
}

.form-radio-group-inline > *:last-child {
  margin-right: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
}
.cc-icon-small {
  width: 16px;
  height: 16px;
}

.cc-icon-medium {
  width: 24px;
  height: 24px;
}

.cc-icon-large {
  width: 32px;
  height: 32px;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  font-size: var(--font-size);
  min-width: 50%;
  overflow: auto;
  padding: 3em;
  z-index: 120;
}

.modal .modal-close {
  position: absolute;
  right: 0;
  top: 0;
  margin: 2em;
  max-height: 100vh;
}

.modal .modal-close:hover {
  color: var(--primary-color);
}


@media only screen and (max-width: 1024px) {
  .modal {
    width: 70%;
  }
}

@media only screen and (max-width: 480px) {
  .modal {
    height: 100%;
    width: 100%;
  }
}

.drawer-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size);
  min-width: 33vw;
  max-width: 66vw;
  padding: 2em;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) 0 10px 10px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  z-index: 120;
}

.drawer-header:before,
.drawer-actions:before {
  content: '';
  position: absolute;
  height: 0.5em;
  width: 100%;
  pointer-events: none;
}

.drawer-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 0.5em;
}

.drawer-header:before {
  top: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(#fff), to(0));
  background: -webkit-linear-gradient(#fff, #fff, 0);
  background: linear-gradient(#fff, #fff, 0);
}

.drawer-titles {
  flex-grow: 1;
  font-weight: 400;
}

.drawer-title {
  font-size: 1.5em;
  font-weight: 700;
}

.drawer-subtitle {
  margin-top: 0.5em;
  font-size: 0.8em;
  font-weight: 200;
}

.drawer-content {
  overflow: auto;
  height: 100%;
  padding: 1em 0;
}

.drawer-actions {
  position: relative;
  padding-top: 0.5em;
}

.drawer-actions:before {
  bottom: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#fff));
  background: -webkit-linear-gradient(transparent, #fff);
  background: linear-gradient(transparent, #fff);
}

@media only screen and (max-width: 480px) {
  .drawer-sidebar {
    left: 0;
    max-width: 100%;
    width: 100%;
    padding: 1em;
  }

  .drawer-actions {
    text-align: center;
  }

  .drawer-actions > * {
    width: 100%;
  }
}

.confirmation-popin {
  min-width: 400px;
}

.confirmation-popin .confirmation-title {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
}

.confirmation-popin .confirmation-content {
  font-size: 1.2em;
  font-weight: 200;
  margin-bottom: 2em;
}

.confirmation-popin > .confirmation-actions {
  display: flex;
  justify-content: flex-end;
}

.confirmation-popin > .confirmation-actions > button:not(:first-child) {
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .confirmation-popin {
    display: flex;
    flex-direction: column;
  }

  .confirmation-popin .confirmation-text {
    flex-grow: 1;
  }

  .confirmation-popin > .confirmation-actions {
    flex-grow: 0;
  }

  .confirmation-popin > .confirmation-actions > button {
    width: 100%;
  }
}
.item-box.btn.btn-link {
  display: flex;
  align-items: center;
  min-width: 0;
  min-width: initial;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  border: 1px solid var(--grey-color);
  background: none;
  color: var(--primary-color);
}

.item-box > a {
  margin-left: 1em;
}

.items-form {
  display: flex;
  flex-wrap: wrap;
}

.items-form > button {
  min-width: 0;
  min-width: initial;
  margin: 0 0.5em 0.5em 0;
}

.items-form > div {
  height: 100%;
  margin: 0 0.5em 0.5em 0;
}

.profile .profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  padding: 0;
}

.profile .profile-avatar {
  --cc-avatar-height: 100px;
  --cc-avatar-width: 100px;
  --cc-avatar-font-size: 70px;
  margin-right: 1.5em;
}

.profile .profile-avatar img {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.profile .profile-header h1 {
  margin-top: 0;
  color: var(--primary-color);
}

.profile .profile-form {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.profile .profile-form > * {
  margin-bottom: 0.7em;
}

.profile .profile-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .profile .profile-header {
    margin-bottom: 1em;
  }

  .profile .profile-header > img {
    margin-right: 1em;
  }

  .profile .profile-header h1 {
    font-size: 1.2em;
  }
}

.sidebar {
  background-color: var(--primary-color);
  height: 100%;
}

.sidebar .sidebar-panel {
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-header {
  color: var(--grey-color-dark);
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 1px;
  overflow: hidden;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-top: 1.5em;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.sidebar a.sidebar-link {
  color: var(--grey-color-light);
  text-decoration: none;
  padding: 0.7em 1em;
}

.sidebar a.sidebar-link-active {
  color: var(--secondary-color);
  background: var(--primary-color-light);
}

.sidebar a.sidebar-link:hover {
  color: var(--primary-color-font);
  background: var(--primary-color-light);
  text-decoration: none;
}

.sidebar a.sidebar-link-active:hover {
  color: var(--secondary-color);
}

.event-form {
  display: flex;
  flex-direction: column;
}

.event-form > * {
  margin-bottom: 0.7em;
}

.event-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .event-form {
    padding: 1em;
  }
}

ul.event-tabs {
  padding: 2em;
  margin-bottom: 2em;
  justify-content: flex-start;
  align-items: center;
}

ul.event-tabs a {
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .event-tabs {
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    font-size: 0.9em;
    align-items: flex-start;
    justify-content: space-around;
  }

  ul.event-tabs.tabs > li {
    margin: 0;
    padding-top: 1em;
  }
  ul.event-tabs.tabs > li:first-child {
    padding-top: 0;
  }
}

.category-form > h2 {
  margin: 0;
  padding-bottom: 2em;
}

.category-form .category-form-content {
  display: flex;
  flex-direction: column;
}

.category-form .category-form-content > div {
  margin-bottom: 0.7em;
}

.category-form .category-form-content > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .category-form .category-form-content {
    padding: 1em;
  }
}

.format-form > h2 {
  margin: 0;
  padding-bottom: 2em;
}

.format-form .format-form-content {
  display: flex;
  flex-direction: column;
}

.format-form .format-form-content > div {
  margin-bottom: 0.7em;
}

.format-form .format-form-content > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .format-form .format-form-content {
    padding: 1em;
  }
}

.cfp-form {
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.cfp-form > * {
  margin-bottom: 0.7em;
}

.cfp-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .cfp-form {
    padding: 1em;
  }
}

.survey-form {
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.survey-form > * {
  margin-bottom: 0.7em;
}

.survey-label {
  color: var(--grey-color-dark);
}

.survey-form .form-checkbox {
  margin: 1em 0;
}

.survey-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .survey-form {
    padding: 1em;
  }
}

.deliberation_form__1ca4S {
  padding: 2em;
  display: flex;
  flex-direction: column;
}

.deliberation_form__1ca4S > * {
  margin-bottom: 1em;
}

.deliberation_form__1ca4S > *:last-child {
  margin-bottom: 0;
}

.deliberation_label__2Fu7w {
  display: flex;
  align-items: center;
}

.deliberation_description__zNFu2 {
  margin-left: 2em;
  font-size: 0.9em;
  color: var(--grey-color-dark);
}

@media only screen and (max-width: 480px) {
  .deliberation_form__1ca4S {
    padding: 1em;
  }
}

.api-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.api-card .api-name {
  font-weight: 700;
  font-size: 1.2em;
}

.api-card .api-description {
  margin-top: 1em;
}

.api-form {
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.api-form > * {
  margin-bottom: 0.7em;
}

.api-form > .generate-key-input > div {
  display: flex;
}

.api-form .api-label {
  margin-top: 2em;
  color: var(--grey-color-dark);
}


.api-form > .generate-key-input button {
  margin-left: 1em;
}

.api-form > button {
  align-self: flex-end;
}

.api-title {
  margin-top: 2em;
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .api-form {
    padding: 1em;
  }
}

.titlebar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 2em;
}

.titlebar .titlebar-title {
  font-size: 1.5em;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  overflow: hidden;
}

.titlebar .titlebar-actions {
  display: flex;
  align-items: center;
}

.titlebar .titlebar-actions > * {
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .titlebar {
    flex-direction: column;
    margin-bottom: 1em;
  }

  .titlebar .titlebar-title {
    margin: 0.5em 0;
  }

  .titlebar .titlebar-actions {
    flex-direction: column;
    width: 100%;
  }

  .titlebar .titlebar-actions > * {
    margin: 0;
    margin-top: 1em;
  }
}

.list {
  background: #fff;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  padding: 0;
  width: 100%;
}

.list > div {
  border-bottom: 1px solid var(--grey-color);
}

.list > div:last-child {
  border-bottom: none;
}

.list .no-result {
  margin: 1em;
  color: var(--grey-color-dark);
}

@media only screen and (max-width: 480px) {
  .list {
    border-radius: 0;
    margin: 0;
  }
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 1em 1.2em;
  min-height: 70px;
}

.list-item.clickable {
  cursor: pointer;
}

.list-item.clickable:hover {
  background: var(--background-color);
}

.list-item .list-item-title {
  font-size: 1.2em;
  font-weight: 600;
}

.list-item .list-item-subtitle {
  font-size: 0.9em;
  margin-top: 0.5em;
  color: var(--grey-color-dark);
}

.list-item .list-item-info {
  display: flex;
}

.list-item .list-item-info > * {
  margin-left: 0.5em;
}

@media only screen and (max-width: 480px) {
  .list-item {
    font-size: 1.1em;
    padding: 0.8em;
  }

  .list-item .list-item-info > * {
    margin-left: 0;
    margin-top: 0.8em;
    margin-right: 0.5em;
  }
}

.events_title__1yVDn {
  margin-bottom: 0.7em;
}

.events_infos__3Uns8 {
  color: var(--grey-color-dark);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.events_type__3LnsE {
  margin-left: 1em;
}

.events_dates__atYwS {
  font-size: 0.8em;
  margin-top: 1em;
}

@media only screen and (max-width: 480px) {
  .events_infos__3Uns8 {
    display: none;
  }
}

.users-result {
  display: flex;
  flex-direction: column;
}

.users-result > h3 {
  margin-top: 1em;
}

.users-result > div {
  padding: 1em;
  cursor: pointer;
  border: 1px solid  var(--grey-color);
  border-bottom: none;
}

.users-result > div:last-child {
  border: 1px solid  var(--grey-color);
}

.users-result > div:hover {
  background: var(--grey-color-light)
}

.users-result > div img {
  font-size: 1.5em;
}

.users-no-result {
  margin-top: 1em;
  color: var(--error-color);
}

.users-no-result > div {
  margin-top: .5em;
}

.add-user-modal > h1 {
  margin-bottom: 1.5em;
}

.add-user-modal .input-button {
  margin-top: 2em;
}

.user-search-separator {
  margin: 2em 0;
  text-align: center;
}

.remove-member-modal > h1 {
  color: var(--error-color);
  margin-bottom: 1.5em;
}

.remove-member-modal a {
  margin-top: 2em;
}

.member {
  display: flex;
  align-items: center;
}

.member > *:first-child {
  margin-right: 10px;
}

.organization-form {
  display: flex;
  flex-direction: column;
}

.organization-form > * {
  margin-bottom: 0.7em;
}

.organization-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .organization-form {
    padding: 1em;
  }
}

.invite-organizer .invited-by {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.invite-organizer .invited-by img {
  font-size: 1.5em;
}

.invite-organizer h2 {
  margin: 1.5em 0 2em 0;
}

.invite-join-btn {
  margin-top: 1em;
}

.proposalsToolbar_proposalsToolbar__ebJxd {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.proposalsToolbar_proposalsFilters__3SxhV {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}

.proposalsToolbar_proposalsFilters__3SxhV > * {
  margin-right: 1em;
}

.proposalsToolbar_proposalsFilters__3SxhV > select {
  width: auto;
  width: initial;
  min-width: 10em;
  max-width: 15em;
}

.proposalsToolbar_proposalsFilters__3SxhV > input {
  width: auto;
  width: initial;
  min-width: 10em;
  max-width: 15em;
}

.proposalsToolbar_proposalsActions__3KUqh {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 480px) {
  .proposalsToolbar_proposalsToolbar__ebJxd {
    display: none;
  }
}

.inline-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inline-items-separator {
  margin: 0 .8em;
}

.proposal-subtitle {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.proposal-subtitle-item {
  display: flex;
  align-items: center;
}

.proposal-item-info-speaker {
  margin-right: 1em;
}

.proposal-item-info-speaker:last-child {
  margin-right: 0;
}

.rating-display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 8em;
  margin-right: 0.5em;
}

.rating-display-rate {
  font-size: 1.7em;
  margin-left: 0.4em;
}

.rating-display-feelings {
  display: flex;
  font-size: 0.7em;
}

.rating-display-feelings .icon-label-right .fa {
  margin-left: 0.3em;
}

.rating-display-feelings .votes {
  color: var(--grey-color-dark);
  margin-left: 1em;
  margin-top: 0.5em;
}

.rating-display-feelings .loves {
  color: #ed4842;
  margin-left: 1em;
  margin-top: 0.5em;
}

.rating-display-feelings .hates {
  color: #000;
  margin-left: 1em;
  margin-top: 0.5em;
}

.rating-display-feelings .noopinion {
  color: #ed4842;
  margin-left: 1em;
  margin-top: 0.5em;
}
.proposal-item-info {
  display: flex;
  align-items: center;
}
div.event-proposals {
  padding: 0;
  width: 100%;
}

.event-proposals > div {
  border-bottom: 1px solid var(--grey-color);
}

.event-proposals > div:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 480px) {
  .event-proposals {
    border-radius: 0;
    margin: 0;
  }
}

.proposal-card {
  width: 18rem;
  height: 20rem;
  margin-right: 2em;
  margin-bottom: 2em;
  border-radius: 6px;
  background: #fff;
  border: 1px solid var(--grey-color);
  border-top: 5px solid var(--primary-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.proposal-card:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.18);
  border-top-color: var(--secondary-color);
}

.proposal-card-header {
  font-weight: 700;
  font-size: 1.1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 40%;
  background: hsl(200, 25%, 96%);
}

.proposal-card-title {
  overflow: hidden;
}

.proposal-card-speakers {
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  color: var(--grey-color-dark);
  border-top: 1px solid var(--grey-color-light);
  font-size: 0.8em;
}

.proposal-card-speakers > *:first-child {
  width: 70%;
}

.proposal-card-speakers > *:last-child {
  text-align: right
}

.proposal-card-speakers .avatar-label-fullname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.proposal-card-survey {
  height: 10%;
  padding: 0 1em;
  font-size: 0.8em;
  width: 100%;
  color: var(--grey-color-dark);
}

.proposal-card-survey > * {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--grey-color);
  border-radius: 0.4em;
  padding: .5em;
}

.proposal-card-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--grey-color-dark);
  padding: 0.5em 0;
  height: 10%;
}

.proposal-card-info > * {
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: 700;
  text-align: center;
  border: 1px solid var(--grey-color);
  padding: .5em;
  margin: 0 1em;
  border-radius: 0.4em;
}

.proposal-card-info > *:first-child {
  margin-right: 0.5em;
}

.proposal-card-info > *:last-child {
  margin-left: 0.5em;
}

.proposal-card-rating {
  height: 20%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: .5em;
  font-size: 1.2em;
}

.proposal-card-rating > * {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse;
  width: 100%;
}

@media print {
  .proposal-card {
    width: calc((210mm / 3) - 10mm);
    height: calc((297mm / 4) - 10mm);
    margin: 2mm;
    border: 1px solid var(--grey-color-dark);
    box-shadow: none;
  }

  .proposal-card:hover {
    box-shadow: none;
    border-top-color: inherit;
  }

  .proposal-card-header {
    background: none;
  }
}

.proposals-cards {
  display: inline-flex;
  flex-wrap: wrap;
}

.proposalsPaging_paging__3t6MI {
  display: flex;
  margin: 1em 0;
}

.proposalsPaging_button__23tyH {
  padding: 10px;
  background: #fff;
  cursor: pointer;
  border: 1px solid var(--grey-color);
}

.proposalsPaging_previous__2-tlM {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right-width: 0px;
  width: 80px;
}

.proposalsPaging_next__3WDUf {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left-width: 0px;
  width: 80px;
}

.proposalsPaging_status__1jdOb {
  text-align: center;
  color: var(--grey-color-dark);
}

@media only screen and (max-width: 1023px) {
  .proposalsPaging_status__1jdOb {
    flex-grow: 1;
  }
}

.proposal-talk-badges {
  margin-bottom: 1em;
}

.proposal-talk-badges > * {
  margin-right: 1em;
}

.proposal-talk-info > h3 {
  margin-top: 2em;
}

.speaker_avatar__1eBbW {
  margin-bottom: 1.5em;
  font-weight: 600;
}

.speaker_avatar__1eBbW img {
  font-size: 1.5em;
}

.speaker_icons__27vzG {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1em;
}

.speaker_bio__2nscZ {
  margin-top: 1em;
}

.cc-tooltip {
  font-size: .8em;
}

.cc-tooltip[data-placement^="top"] .cc-tooltip-arrow  {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  bottom: 0;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip[data-placement^="bottom"] .cc-tooltip-arrow  {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  top: 0;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip[data-placement^="left"] .cc-tooltip-arrow  {
  border-width: 5px 0 5px 5px;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  right: -5px;
  top: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip[data-placement^="right"] .cc-tooltip-arrow  {
  border-width: 5px 5px 5px 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  left: -5px;
  top: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.cc-tooltip .cc-tooltip-arrow  {
  border-color: var(--primary-color);
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
}

.cc-tooltip .cc-tooltip-inner {
  margin: 5px;
  padding: .8em;
  background-color: var(--primary-color);
  color: var(--primary-color-font);
  border-radius: .2em;
}
.rating {
  display: flex;
  align-items: center;
}

.rating-label {
  cursor: pointer;
}

.rating > span, .rating > i {
  cursor: pointer;
  padding: 0 0.5em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.rating > span > i {
  padding: 0 0.5em;
}

.rating-noopinion {
  color: #ed4842;
}

.rating-noopinion-disable {
  color: #ccc;
}

.rating-hate {
  color: #000;
}

.rating-hate-disable {
  color: #ccc;
}

.rating-star {
  color: #ffc74c;
}

.rating-love {
  color: #ed4842;
}

.bounce {
  -webkit-animation: bounce 0.8s;
          animation: bounce 0.8s;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0.7;
  }
  60% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0.7;
  }
  60% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media only screen and (max-width: 480px) {
  .rating-label {
    display: none;
  }

  .rating > span, .rating > i {
    padding: 0 0.3em;
  }

  .rating > span > i {
    padding: 0;
  }
}

.proposal-ratings-layout {
  display: grid;
  grid-template-columns: auto 2fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'btn-previous btn-ratings btn-next';
  grid-gap: 1em;
  align-items: center;
}

.btn-previous {
  grid-area: btn-previous;
  justify-self: flex-start;
}

.btn-ratings {
  grid-area: btn-ratings;
  justify-self: center;
}

.btn-next {
  grid-area: btn-next;
  justify-self: flex-end;
}

@media only screen and (max-width: 1080px) {
  .proposal-ratings-layout {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'btn-previous btn-next' 'btn-ratings btn-ratings';
  }
}


.team-ratings-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
  font-weight: 200;
  padding: 1em;
  border: 1px solid var(--grey-color-light);
  background: #FEFEFE;
  color: var(--grey-color-dark);
}

.team-ratings-total-label {
  font-size: 1.5em;
}

.team-ratings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0;
  font-weight: 200;
}

.team-ratings-rates {
  margin-left: 2em;
  font-size: 1.3em;
}


.team-ratings-rates .fa-star {
  color: #ffc74c;
}

.team-ratings-rates .fa-heart {
  color: #ed4842;
}

.team-ratings-rates .fa-circle {
  color: #000;
}

.team-ratings-rates .fa-ban {
  color: #ed4842;
}
.speaker-surveys {
  margin: 2em 0;
  max-width: 500px;
}

.speaker-surveys img {
  font-size: 1.2em;
}

.speaker-surveys .questions {
  margin-top: 1em;
  font-weight: 200;
}

.speaker-surveys .questions .question {
  padding: 0.5em 0;
}

.speaker-surveys .questions .question .answer {
  margin-left: 1em;
  color: var(--grey-color-dark);
  font-weight: 700;
}

.speaker-surveys .not-answered {
  margin-top: 1.5em;
  font-style: italic;
  font-weight: 200;
}
.message_wrapper__1LavX {
  display: flex;
  align-items: flex-start;
}

.message_wrapper__1LavX:hover .message_edit__2VMEk {
  visibility: visible;
}

.message_wrapper__1LavX:hover .message_trash__1oejQ {
  visibility: visible;
}

.message_messageContent__1o-wC {
  width: 100%;
}

.message_avatar__PfRUm {
  margin-right: 0.5em;
}

.message_message__Gh3OB {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.3em;
}

.message_name__2HTvm {
  font-weight: 700;
  margin-right: 0.5em;
}

.message_date__jigoq {
  font-size: 0.8em;
  color: var(--grey-color-dark);
  margin-right: 0.2em;
}

.message_modified__1XBFo {
  font-size: 0.8em;
  color: var(--grey-color-dark);
  margin-right: 0.4em;
}

.message_edit__2VMEk {
  color: var(--grey-color-dark);
  visibility: hidden;
}

.message_edit__2VMEk:hover {
  color: var( --primary-color-dark);
  cursor: pointer;
}

.message_trash__1oejQ {
  color: var(--grey-color-dark);
  visibility: hidden;
  margin-left: 0.5em;
}

.message_trash__1oejQ:hover {
  color: var(--error-color);
  cursor: pointer;
}

.message_editInput__ZEWVH {
  flex-shrink: 0;
  display: flex;
}

.message_editInput__ZEWVH button {
  margin-left: 0.5em;
}

.message_previewMessageDelete__dbQ7b {
  margin-top: 2em;
}

@media only screen and (max-width: 480px) {
  .message_message__Gh3OB {
    flex-direction: column;
  }
}
.thread_thread__dxoH_ {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.thread_description__1c_KQ {
  padding-top: 1em;
  padding-bottom: 2em;
  flex-grow: 1;
}

.thread_messages__1guxv {
  overflow: auto;
  padding: 1em 1em 1em 0;
}

.thread_message__2PVqX {
  margin-top: 1.5em;
}

.thread_input__yWlDQ {
  flex-shrink: 0;
  display: flex;
}

.thread_input__yWlDQ button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.thread_input__yWlDQ input[type=text] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.organizersThread_organizersThread__3e_nu {
  max-width: 600px;
}
.actions_header__2zmwv {
  margin-bottom: 0;
}

.liker_avatar__1BiN2 {
  margin-bottom: 1.5em;
  font-weight: 600;
}

.liker_avatar__1BiN2 img {
  font-size: 1.5em;
}

.liker_icons__1nV4I {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1em;
}

.liker_bio__3UhSf {
  margin-top: 1em;
}

.proposal {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'proposal-actions proposal-actions' 'proposal-ratings proposal-ratings' 'proposal-talk proposal-speakers' 'proposal-likers proposal-likers';
}

.proposal .card {
  padding: 1.5em;
}

.proposal-ratings {
  grid-area: proposal-ratings;
  align-self: start;
}

.proposal-actions {
  grid-area: proposal-actions;
}

.proposal-actions:empty {
  margin-bottom: 0;
  padding: 0;
}

.proposal-talk {
  grid-area: proposal-talk;
}

.proposal-speakers {
  grid-area: proposal-speakers;
}

.proposal-speakers > div {
  border-bottom: 1px solid var(--grey-color-light);
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.proposal-speakers > div:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.proposal-likers {
  grid-area: proposal-likers;
}

@media only screen and (max-width: 480px) {
  .proposal {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'proposal-ratings'
      'proposal-talk'
      'proposal-speakers'
      'proposal-likers';
  }

  .proposal-actions {
    display: none;
  }

  .proposal .card {
    padding: 1em;
  }
}

.talk-info-space {
  margin: 0 1em;
}
.talk-form {
  display: flex;
  flex-direction: column;
}

.talk-form > * {
  margin-bottom: 0.7em;
}

.talk-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .talk-form {
    padding: 1em;
  }
}

.step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  color: var(--grey-color-dark);
  border: 2px solid var(--grey-color-dark);
}

.step-active {
  color: var(--secondary-color-font);
  background: var(--secondary-color);
  border: 2px solid var(--secondary-color);
}

.step-success {
  color: #fff;
  background: var(--success-color);
  border: 2px solid var(--success-color);
}

.step-active-label {
  color: var(--secondary-color);
}

.step-success-label {
  color: var(--success-color);
}

.step-space {
  height: 2px;
  flex-grow: 2;
  background: var(--grey-color-dark);
}

.step-space.space-success {
  background: var(--success-color);
}

.step-space.space-active-to-default {
  background: -webkit-gradient(linear, left top, right top, from(var(--secondary-color)), to(var(--grey-color-dark)));
  background: -webkit-linear-gradient(left, var(--secondary-color), var(--grey-color-dark));
  background: linear-gradient(to right, var(--secondary-color), var(--grey-color-dark));
}

.step-space.space-success-to-active {
  background: -webkit-gradient(linear, left top, right top, from(var(--success-color)), to(var(--secondary-color)));
  background: -webkit-linear-gradient(left, var(--success-color), var(--secondary-color));
  background: linear-gradient(to right, var(--success-color), var(--secondary-color));
}

.stepper {
  margin: 3em 0 4em 0;
  width: 70%;
}

.stepper .steps-labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  font-weight: 600;
  color: var(--grey-color-dark);
}

.stepper .steps-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 480px) {
  .stepper {
    margin: 1em 0 2em 0;
    width: 100%;
  }

  .stepper .steps-labels {
    display: none;
  }
}

.talk-abstract .talk-reference {
  margin-top: 2em;
}

.talk-abstract .talk-abstract-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.talk-abstract .talk-abstract-more-info > * {
  margin-left: 1em;
}


@media only screen and (max-width: 1024px) {
  .talk-abstract .talk-abstract-title {
    flex-direction: column;
  }

  .talk-abstract .talk-abstract-more-info > * {
    margin-left: 0;
    margin-right: 1em;
  }
}
a.add-speaker-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--grey-color-dark);
}

.add-speaker-button-icon {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: var(--grey-color-light);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-speaker-button-label {
  margin-left: .8em;
  text-decoration: underline;
}
a.remove-speaker {
  color: var(--grey-color-dark);
}
.talk-speakers {
  margin-bottom: 2em;
}

.talk-speakers > h3 {
  margin-bottom: 2em;
}

.talk-speaker-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.talk-speaker-row img {
  font-size: 1.4em;
}

.submission-status {
  display: flex;
  align-items: center;
  justify-self: flex-start;
}

.submission-status > * {
  margin-left: 1em;
}

.submission-status > *:first-child {
  margin-left: 0;
}

.submission-status .status-warning {
  background: var(--warning-color);
}

.submission-status .status-warning > a {
  color: #fff;
  text-decoration: none;
}

.submission-status .status-closed {
  background: var(--error-color);
}

.talk-submission-event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
}

.talk-submission-event a {
  cursor: pointer;
}

.talk-submission-event .talk-submission-event-actions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.talk-submission-event .talk-submission-event-actions > * {
  margin-left: 1em;
}

.talk-submit {
  margin-top: 30px;
}

.talk-title {
  color: var(--primary-color);
  font-weight: 600;
}

.talk-title > i {
  margin-right: 15px;
}

.notification_notification__2N0s_ {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  color: #fff;

  padding: 1em;

  background-color: var(--secondary-color-light);
  border-radius: 3px;
}

.notification_message__3OSVI {
  font-size: 1.2em;
}

.notification_actions__2WhqR {
  display: flex;
}

.notification_actions__2WhqR > button:last-child {
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .notification_notification__2N0s_ {
    flex-direction: column;
    margin-bottom: 1em;
  }

  .notification_actions__2WhqR {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    width: 100%;
  }

  .notification_actions__2WhqR > button:last-child {
    margin-top: 0.5em;
    margin-left: 0;
  }
}


.deliberation_deliberation__JedSc > * {
  margin-bottom: 2em;
}

.eventsSelection_title__1HSCF {
  margin-bottom: 0.7em;
}

.eventsSelection_type__2_QWp {
  margin-left: 1em;
  font-size: 0.7em;
}

.eventsSelection_dates__yNoAv {
  margin-bottom: 0.5em;
}
.submission {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-talk-modal > h1 {
  color: var(--error-color);
  margin-bottom: 1.5em;
}

.delete-talk-modal .input-button {
  margin-top: 2em;
}

.talk-page {
  display: grid;
  grid-template-columns: auto 30%;
  grid-template-rows: auto;
  grid-template-areas: 'talk-content talk-info';
  grid-gap: 2em;
}

.talk-page .talk-content {
  grid-area: talk-content;
}

.talk-page .talk-info {
  grid-area: talk-info;
  align-self: start;
}

@media only screen and (max-width: 1024px) {
  .talk-page {
    grid-template-columns: auto 30%;
    grid-template-rows: auto auto;
    grid-template-areas: 'talk-content talk-content' 'talk-info talk-info';
    grid-gap: 1em;
  }
}

.invite-speaker .invited-by {
  font-weight: 600;
}

.invite-speaker .invited-by img {
  font-size: 1.5em;
}

.invite-speaker h2 {
  margin: 1.5em 0 2em 0;
}

.invite-join-btn {
  margin-top: 1em;
}

.talk-title {
  display: flex;
  align-items: center;
}

.talk-title .talk-status {
  margin-left: 1em;
  align-items: center;
  font-size: 0.7em;
  letter-spacing: normal;
  font-weight: normal;
  text-transform: none;
}

.talk-details {
  width: 100%;
}

.talk-page {
  display: grid;
  grid-template-columns: auto 30%;
  grid-template-rows: auto;
  grid-template-areas: 'talk-content talk-info';
  grid-gap: 2em;
}

.talk-page .talk-content {
  grid-area: talk-content;
}

.talk-page .talk-info {
  grid-area: talk-info;
  align-self: start;
}

.talk-page .talk-info .talk-submissions > div:last-child {
  padding-bottom: 0;
}

.margin-gap {
  margin-top: 2em;
}

@media only screen and (max-width: 1024px) {
  .talk-page {
    grid-template-columns: auto 30%;
    grid-template-rows: auto auto;
    grid-template-areas: 'talk-content talk-content' 'talk-info talk-info';
    grid-gap: 1em;
  }

  .margin-gap {
    margin-top: 1em;
  }
}

.talk-submission {
  width: 100%;
}

.submit-talk-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.submit-talk-form > * {
  margin-bottom: 0.7em;
}

.icon-submitted {
  text-align: center;
  color: var(--grey-color-dark);
}

.icon-submitted > i {
  font-size: 8em;
  margin: 0.3em;
}

.icon-submitted > h2 {
  font-weight: 600;
  color: var(--success-color);
}

.submitWizard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submissionPage_submission__3D9Kc {
  display: grid;
  grid-template-columns: auto 30%;
  grid-template-rows: auto;
  grid-template-areas: 'talk-header talk-header' 'talk-content talk-info';
  grid-gap: 2em;
}

.submissionPage_header__3ycOf {
  grid-area: talk-header;
}

.submissionPage_content__3DYsy {
  grid-area: talk-content;
}

.submissionPage_info__uq2PW {
  grid-area: talk-info;
  align-self: start;
}

.submissionPage_notification__bAncc {
  margin-bottom: 2em;
}

.submissionPage_status__2Zw4A {
  display: flex;
}

.submissionPage_status__2Zw4A > * {
  margin-right: 1em;
}

.submissionPage_status__2Zw4A > *:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1024px) {
  .submissionPage_submission__3D9Kc {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'talk-header' 'talk-content' 'talk-info';
    grid-gap: 1em;
  }
}

.survey-input {
  margin-bottom: 1em;
}

.survey-input > label {
  display: block;
  font-weight: bold;
  padding-bottom: 0.5em;
}

.survey-input > div {
  padding-bottom: 0.5em;
}

.survey-input > div input {
  margin-right: 0.5em;
}

.speaker-survey > h1 {
  margin-bottom: 2em;
}

.speaker-survey .card > p {
  color: var(--grey-color-dark);
  margin-bottom: 2em;
}

.proposal-subtitle {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.proposal-subtitle-item {
  display: flex;
  align-items: center;
}

.proposal-item-info-speaker {
  margin-right: 1em;
}

.proposal-item-info-speaker:last-child {
  margin-right: 0;
}

div.event-proposals {
  padding: 0;
  width: 100%;
}

.event-proposals > div {
  border-bottom: 1px solid var(--grey-color);
}

.event-proposals > div:last-child {
  border-bottom: none;
}

@media only screen and (max-width: 480px) {
  .event-proposals {
    border-radius: 0;
    margin: 0;
  }
}

.proposal-card {
  width: 18rem;
  height: 20rem;
  margin-right: 2em;
  margin-bottom: 2em;
  border-radius: 6px;
  background: #fff;
  border: 1px solid var(--grey-color);
  border-top: 5px solid var(--primary-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.proposal-card:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.18);
  border-top-color: var(--secondary-color);
}

.proposal-card-header {
  font-weight: 700;
  font-size: 1.1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 40%;
  background: hsl(200, 25%, 96%);
}

.proposal-card-title {
  overflow: hidden;
}

.proposal-card-speakers {
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  color: var(--grey-color-dark);
  border-top: 1px solid var(--grey-color-light);
  font-size: 0.8em;
}

.proposal-card-speakers > *:first-child {
  width: 70%;
}

.proposal-card-speakers > *:last-child {
  text-align: right
}

.proposal-card-speakers .avatar-label-fullname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.proposal-card-survey {
  height: 10%;
  padding: 0 1em;
  font-size: 0.8em;
  width: 100%;
  color: var(--grey-color-dark);
}

.proposal-card-survey > * {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--grey-color);
  border-radius: 0.4em;
  padding: .5em;
}

.proposal-card-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--grey-color-dark);
  padding: 0.5em 0;
  height: 10%;
}

.proposal-card-info > * {
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: 700;
  text-align: center;
  border: 1px solid var(--grey-color);
  padding: .5em;
  margin: 0 1em;
  border-radius: 0.4em;
}

.proposal-card-info > *:first-child {
  margin-right: 0.5em;
}

.proposal-card-info > *:last-child {
  margin-left: 0.5em;
}

.proposal-card-rating {
  height: 20%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: .5em;
  font-size: 1.2em;
}

.proposal-card-rating > * {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse;
  width: 100%;
}

@media print {
  .proposal-card {
    width: calc((210mm / 3) - 10mm);
    height: calc((297mm / 4) - 10mm);
    margin: 2mm;
    border: 1px solid var(--grey-color-dark);
    box-shadow: none;
  }

  .proposal-card:hover {
    box-shadow: none;
    border-top-color: inherit;
  }

  .proposal-card-header {
    background: none;
  }
}

.proposals-cards {
  display: inline-flex;
  flex-wrap: wrap;
}

.proposal-talk-badges {
  margin-bottom: 1em;
}

.proposal-talk-badges > * {
  margin-right: 1em;
}

.proposal-talk-info > h3 {
  margin-top: 2em;
}

.like {
  display: flex;
  align-items: center;
}

.like > i {
  cursor: pointer;
  padding: 0 0.5em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.like-isLiked {
  color: #ed4842;
}

.bounce {
  -webkit-animation: bounce 0.8s;
          animation: bounce 0.8s;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0.7;
  }
  60% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
    opacity: 0.7;
  }
  60% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    opacity: 1;
  }
  80% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media only screen and (max-width: 480px) {
  .like > i {
    padding: 0 0.3em;
  }
}

.proposal-ratings-layout {
  display: grid;
  grid-template-columns: auto 2fr auto;
  grid-template-rows: auto;
  grid-template-areas: 'btn-previous btn-ratings btn-next';
  grid-gap: 1em;
  align-items: center;
}

.btn-previous {
  grid-area: btn-previous;
  justify-self: flex-start;
}

.btn-ratings {
  grid-area: btn-ratings;
  justify-self: center;
}

.btn-next {
  grid-area: btn-next;
  justify-self: flex-end;
}

@media only screen and (max-width: 1080px) {
  .proposal-ratings-layout {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'btn-previous btn-next' 'btn-ratings btn-ratings';
  }
}


.proposalLike {
  display: grid;
  grid-gap: 1em;
  grid-template-rows: auto;
  grid-template-areas: 'proposalLike-ratings' 'proposalLike-talk';
}

.proposalLike .card {
  padding: 1.5em;
}

.proposalLike-ratings {
  grid-area: proposalLike-ratings;
  align-self: start;
}

.proposalLike-actions:empty {
  margin-bottom: 0;
  padding: 0;
}

.proposalLike-talk {
  grid-area: proposalLike-talk;
}

@media only screen and (max-width: 480px) {
  .proposalLike {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'proposalLike-ratings'
      'proposalLike-talk'
  }

  .proposalLike-actions {
    display: none;
  }

  .proposalLike .card {
    padding: 1em;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', Calibri, Arial, sans-serif;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.app {
  background: var(--background-color);
  color: var(--primary-color);
  font-size: var(--font-size);
  height: 100%;
  overflow: hidden;
}

@media print{
  @page {
    size: A4;
    margin: 0;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }

  .app {
    height: auto;
    overflow: visible;
    background: #fff;
  }

  .no-print {
    display: none;
  }
}
.default-theme {
  --font-size: 14px;
  --background-color: #edf2f5;

  --primary-color: #2a3244;
  --primary-color-light: #373f51;
  --primary-color-dark: #1f2022;
  --primary-color-font: #fff;

  --secondary-color: #ffab00;
  --secondary-color-light: #ffdd4b;
  --secondary-color-dark: #c67c00;
  --secondary-color-font: #fff;

  --grey-color: #c4c9d6;
  --grey-color-dark: #95949b;
  --grey-color-light: #eee;

  --layout-navbar-height: 60px;
  --layout-sidebar-width: 240px;

  --info-color: #fff;
  --success-color: #7fba01;
  --question-color: #2170bf;
  --error-color: #c73228;
  --warning-color: #ff9900;
}

.red-theme {
  --background-color: #edf2f5;

  --primary-color: #2a3244;
  --primary-color-light: #373f51;
  --primary-color-dark: #1f2022;
  --primary-color-font: #fff;

  --secondary-color: #fc1047;
  --secondary-color-light: #fd7fa0;
  --secondary-color-dark: #db0c3e;
  --secondary-color-font: #fff;
}

.blue-theme {
  --background-color: #edf2f5;

  --primary-color: #2a3244;
  --primary-color-light: #373f51;
  --primary-color-dark: #1f2022;
  --primary-color-font: #fff;

  --secondary-color: #0b73ff;
  --secondary-color-light: #0b73ff;
  --secondary-color-dark: #05147d;
  --secondary-color-font: #fff;
}

a {
  color: var(--primary-color);
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

a[role='button'] {
  cursor: pointer;
}

a:hover {
  color: var(--secondary-color);
}

.card {
  background: #fff;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  padding: 2em;
}

input[type='text'],
input[type='search'],
input[type='email'],
select {
  border-radius: 3px;
  border: 1px solid var(--grey-color);
  background: #fff;
  color: #000;
  font-family: inherit;
  outline: none;
  padding: 0 1em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 2.7em;
  line-height: 2.7em;
  width: 100%;
}

input:focus,
select:focus {
  border: 1px solid var(--primary-color);
}

input:disabled,
select:disabled {
  color: var(--grey-color);
}

input::-webkit-input-placeholder {
  color: var(--grey-color-dark);
}

input::-moz-placeholder {
  color: var(--grey-color-dark);
}

input::-ms-input-placeholder {
  color: var(--grey-color-dark);
}

input::placeholder {
  color: var(--grey-color-dark);
}
small {
  color: var(--grey-color-dark);
  font-size: 0.9em;
}

textarea {
  border-radius: 3px;
  border: 1px solid var(--grey-color);
  color: #000;
  font-family: inherit;
  outline: none;
  padding: 10px;
  width: 100%;
  height: 200px;
  resize: vertical;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

textarea:focus {
  border: 1px solid var(--primary-color);
}

h1,
h2,
h3,
h4 {
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0;
  color: var(--grey-color-dark);
}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  letter-spacing: 1px;
  font-size: 1.1em;
}

h4 {
  letter-spacing: 0;
  text-transform: none;
  font-weight: 600;
  margin-bottom: 0;
}

ul.tabs {
  width: 100%;
  display: flex;
}

ul.tabs > li {
  display: inline;
  list-style: none;
  text-transform: uppercase;
  margin-left: 3em;
  font-size: 1.1em;
  letter-spacing: 1px;
}

ul.tabs > li a {
  color: var(--font-color);
}

ul.tabs > li a:hover {
  text-decoration: underline;
}

ul.tabs > li a.tab-active {
  color: var(--secondary-color);
  text-decoration: none;
  cursor: default;
}

ul.tabs > li:first-child {
  margin-left: 0;
}

