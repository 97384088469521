.cc-icon-small {
  width: 16px;
  height: 16px;
}

.cc-icon-medium {
  width: 24px;
  height: 24px;
}

.cc-icon-large {
  width: 32px;
  height: 32px;
}