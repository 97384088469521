.input-button {
  display: flex;
}

.input-button > input[type='search'],
.input-button > input[type='text'] {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: transparent !important;
}

.input-button > input[type='search']:focus,
.input-button > input[type='text']:focus {
  border-color: var(--grey-color);
}

.input-button > button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-button > button:disabled {
  border-color: var(--grey-color);
}

.input-button > button:disabled:hover {
  border-color: var(--grey-color);
}
