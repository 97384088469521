.contributor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contributor-avatar {
  margin-bottom: 1em;
}

.contributor-name {
  font-weight: 600;
}
