.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 1em 1.2em;
  min-height: 70px;
}

.list-item.clickable {
  cursor: pointer;
}

.list-item.clickable:hover {
  background: var(--background-color);
}

.list-item .list-item-title {
  font-size: 1.2em;
  font-weight: 600;
}

.list-item .list-item-subtitle {
  font-size: 0.9em;
  margin-top: 0.5em;
  color: var(--grey-color-dark);
}

.list-item .list-item-info {
  display: flex;
}

.list-item .list-item-info > * {
  margin-left: 0.5em;
}

@media only screen and (max-width: 480px) {
  .list-item {
    font-size: 1.1em;
    padding: 0.8em;
  }

  .list-item .list-item-info > * {
    margin-left: 0;
    margin-top: 0.8em;
    margin-right: 0.5em;
  }
}
