.copy-input {
  display: flex;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  align-items: center;
}

.copy-input .copy-title {
  white-space: nowrap;
  color: var(--grey-color-dark);
  padding: 0 1em;
}

.copy-input > input[type='text'] {
  border: none;
  border-left: 1px solid var(--grey-color);
  border-radius: 0;
}

.copy-input > input[type='text']:focus {
  border: none;
  border-left: 1px solid var(--grey-color);
}

.copy-input > button {
  border: none;
  border-left: 1px solid var(--grey-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.copy-input > button:hover {
  border: none;
  border-left: 1px solid var(--grey-color);
}
