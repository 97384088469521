.talk-form {
  display: flex;
  flex-direction: column;
}

.talk-form > * {
  margin-bottom: 0.7em;
}

.talk-form > button {
  align-self: flex-end;
}

@media only screen and (max-width: 480px) {
  .talk-form {
    padding: 1em;
  }
}
