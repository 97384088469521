.submission {
  display: grid;
  grid-template-columns: auto 30%;
  grid-template-rows: auto;
  grid-template-areas: 'talk-header talk-header' 'talk-content talk-info';
  grid-gap: 2em;
}

.header {
  grid-area: talk-header;
}

.content {
  grid-area: talk-content;
}

.info {
  grid-area: talk-info;
  align-self: start;
}

.notification {
  margin-bottom: 2em;
}

.status {
  display: flex;
}

.status > * {
  margin-right: 1em;
}

.status > *:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1024px) {
  .submission {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'talk-header' 'talk-content' 'talk-info';
    grid-gap: 1em;
  }
}
