.api-form {
  padding: 2em;
  display: flex;
  flex-direction: column;
}
.api-form > * {
  margin-bottom: 0.7em;
}

.api-form > .generate-key-input > div {
  display: flex;
}

.api-form .api-label {
  margin-top: 2em;
  color: var(--grey-color-dark);
}


.api-form > .generate-key-input button {
  margin-left: 1em;
}

.api-form > button {
  align-self: flex-end;
}

.api-title {
  margin-top: 2em;
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .api-form {
    padding: 1em;
  }
}
