.form-label {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-label > label {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  min-width: 20%;
}

.form-label > div {
  width: 100%;
}

.form-has-error > label {
  color: var(--error-color);
}

.form-label .form-error {
  height: 1em;
  font-size: 0.8em;
  color: var(--error-color);
  margin-top: 0.3em;
}

.form-label .input-autocomplete {
  z-index: 1;
}

@media screen and (max-width: 640px) {
  .form-label {
    display: block;
  }
  .form-label > label {
    font-size: 0.9em;
    display: block;
    min-width: 100%;
    padding-top: 0;
    padding-bottom: 0.3em;
  }
}
