.proposalLike {
  display: grid;
  grid-gap: 1em;
  grid-template-rows: auto;
  grid-template-areas: 'proposalLike-ratings' 'proposalLike-talk';
}

.proposalLike .card {
  padding: 1.5em;
}

.proposalLike-ratings {
  grid-area: proposalLike-ratings;
  align-self: start;
}

.proposalLike-actions:empty {
  margin-bottom: 0;
  padding: 0;
}

.proposalLike-talk {
  grid-area: proposalLike-talk;
}

@media only screen and (max-width: 480px) {
  .proposalLike {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
      'proposalLike-ratings'
      'proposalLike-talk'
  }

  .proposalLike-actions {
    display: none;
  }

  .proposalLike .card {
    padding: 1em;
  }
}
