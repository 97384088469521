.title {
  margin-bottom: 0.7em;
}

.type {
  margin-left: 1em;
  font-size: 0.7em;
}

.dates {
  margin-bottom: 0.5em;
}