ul.event-tabs {
  padding: 2em;
  margin-bottom: 2em;
  justify-content: flex-start;
  align-items: center;
}

ul.event-tabs a {
  text-decoration: none;
}

@media only screen and (max-width: 480px) {
  .event-tabs {
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    font-size: 0.9em;
    align-items: flex-start;
    justify-content: space-around;
  }

  ul.event-tabs.tabs > li {
    margin: 0;
    padding-top: 1em;
  }
  ul.event-tabs.tabs > li:first-child {
    padding-top: 0;
  }
}
