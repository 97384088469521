.cfp-block {
  margin: 1em 0;
}

.cfp-block .cfp-block-subtitle {
  font-size: 0.6em;
  margin-top: 1em;
}

.cfp-block.cfp-block-not-started * {
  color: var(--grey-color-dark);
}

.cfp-block.cfp-block-opened * {
  color: var(--success-color);
}

.cfp-block.cfp-block-closed * {
  color: var(--error-color);
}
