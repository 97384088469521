.proposal-card {
  width: 18rem;
  height: 20rem;
  margin-right: 2em;
  margin-bottom: 2em;
  border-radius: 6px;
  background: #fff;
  border: 1px solid var(--grey-color);
  border-top: 5px solid var(--primary-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  cursor: pointer;
  transition: all 0.5s;
}

.proposal-card:hover {
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.18);
  border-top-color: var(--secondary-color);
}

.proposal-card-header {
  font-weight: 700;
  font-size: 1.1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 40%;
  background: hsl(200, 25%, 96%);
}

.proposal-card-title {
  overflow: hidden;
}

.proposal-card-speakers {
  height: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  color: var(--grey-color-dark);
  border-top: 1px solid var(--grey-color-light);
  font-size: 0.8em;
}

.proposal-card-speakers > *:first-child {
  width: 70%;
}

.proposal-card-speakers > *:last-child {
  text-align: right
}

.proposal-card-speakers .avatar-label-fullname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.proposal-card-survey {
  height: 10%;
  padding: 0 1em;
  font-size: 0.8em;
  width: 100%;
  color: var(--grey-color-dark);
}

.proposal-card-survey > * {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid var(--grey-color);
  border-radius: 0.4em;
  padding: .5em;
}

.proposal-card-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--grey-color-dark);
  padding: 0.5em 0;
  height: 10%;
}

.proposal-card-info > * {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.8em;
  font-weight: 700;
  text-align: center;
  border: 1px solid var(--grey-color);
  padding: .5em;
  margin: 0 1em;
  border-radius: 0.4em;
}

.proposal-card-info > *:first-child {
  margin-right: 0.5em;
}

.proposal-card-info > *:last-child {
  margin-left: 0.5em;
}

.proposal-card-rating {
  height: 20%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: .5em;
  font-size: 1.2em;
}

.proposal-card-rating > * {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-flow: row-reverse;
  width: 100%;
}

@media print {
  .proposal-card {
    width: calc((210mm / 3) - 10mm);
    height: calc((297mm / 4) - 10mm);
    margin: 2mm;
    border: 1px solid var(--grey-color-dark);
    box-shadow: none;
  }

  .proposal-card:hover {
    box-shadow: none;
    border-top-color: inherit;
  }

  .proposal-card-header {
    background: none;
  }
}
