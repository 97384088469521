.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  color: #fff;

  padding: 1em;

  background-color: var(--secondary-color-light);
  border-radius: 3px;
}

.message {
  font-size: 1.2em;
}

.actions {
  display: flex;
}

.actions > button:last-child {
  margin-left: 1em;
}

@media only screen and (max-width: 480px) {
  .notification {
    flex-direction: column;
    margin-bottom: 1em;
  }

  .actions {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    width: 100%;
  }

  .actions > button:last-child {
    margin-top: 0.5em;
    margin-left: 0;
  }
}

