.avatar {
  margin-bottom: 1.5em;
  font-weight: 600;
}

.avatar img {
  font-size: 1.5em;
}

.icons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1em;
}

.bio {
  margin-top: 1em;
}
