.avatar-dropdown-button .cc-avatar-image > img {
  border: 2px solid var(--primary-color-font);
  font-size: 1.5em;
}

.avatar-dropdown-button .cc-avatar-image > img:hover {
  border: 2px solid var(--secondary-color);
  cursor: pointer;
}

.avatar-dropdown-button .cc-avatar-initials {
  border: 2px solid var(--secondary-color);
}

.avatar-dropdown-button .cc-avatar-initials:hover {
  border: 2px solid var(--secondary-color-font);
  cursor: pointer;
}

.avatar-dropdown {
  z-index: 100;
}
