.list {
  background: #fff;
  border: 1px solid var(--grey-color);
  border-radius: 3px;
  padding: 0;
  width: 100%;
}

.list > div {
  border-bottom: 1px solid var(--grey-color);
}

.list > div:last-child {
  border-bottom: none;
}

.list .no-result {
  margin: 1em;
  color: var(--grey-color-dark);
}

@media only screen and (max-width: 480px) {
  .list {
    border-radius: 0;
    margin: 0;
  }
}
