.item-box.btn.btn-link {
  display: flex;
  align-items: center;
  min-width: initial;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  border: 1px solid var(--grey-color);
  background: none;
  color: var(--primary-color);
}

.item-box > a {
  margin-left: 1em;
}
