textarea {
  border-radius: 3px;
  border: 1px solid var(--grey-color);
  color: #000;
  font-family: inherit;
  outline: none;
  padding: 10px;
  width: 100%;
  height: 200px;
  resize: vertical;
  transition: all 0.5s;
}

textarea:focus {
  border: 1px solid var(--primary-color);
}
