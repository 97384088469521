.layout-navbar {
  grid-area: navbar;
}

.layout-sidebar {
  grid-area: sidebar;
  overflow: auto;
}

.layout-main {
  grid-area: main;
  overflow: auto;
}

.layout-main > * {
  margin: 3em;
}

.layout-main.layout-main-full-width {
  padding: 0 3em;
}

.layout-main.layout-main-full-width > * {
  margin-right: auto;
  margin-left: auto;
  max-width: 1400px;
}

.layout-brand {
  grid-area: brand;
}

.layout-screen {
  display: grid;
  grid-template-columns: var(--layout-sidebar-width) auto;
  grid-template-rows: var(--layout-navbar-height) auto;
  grid-template-areas: 'brand navbar' 'sidebar main';
  height: 100%;
}

.layout-screen.layout-screen-full-width {
  grid-template-areas: 'brand navbar' 'main main';
}

@media only screen and (max-width: 1023px) {
  .layout-screen {
    grid-template-columns: auto;
    grid-template-areas: 'brand' 'main';
  }
  .layout-screen.layout-screen-full-width {
    grid-template-areas: 'brand' 'main';
  }
  .layout-main.layout-main-full-width {
    padding: 0;
  }
  .layout-navbar {
    display: none;
  }
  .layout-sidebar {
    display: none;
  }
  .layout-main > * {
    margin: 0;
    padding: 1em;
  }
}

@media print{
  .layout-screen {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: 'main';
    height: 100%;
  }
  .layout-navbar {
    display: none;
  }
  .layout-sidebar {
    display: none;
  }
  .layout-brand {
    display: none;
  }
  .layout-main > * {
    margin: 0em;
  }
}