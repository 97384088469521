.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--primary-color);
  color: var(--primary-color-font);
}

.page-not-found > h1 {
  font-size: 3em;
  margin: 0 0 1em 0;
  text-align: center;
}

.page-not-found > a {
  color: var(--secondary-color);
  text-decoration: underline;
}
